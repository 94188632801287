.contenuPhotos {
	padding-left: 30px;
	width: calc(100vw - 225px);
	height: calc(100vh - 55px);
	overflow: scroll;

	.albums {
		.containerTitleInput {
			display: flex;
			width: 300px;

			.titlePage {
				font-size: 27px;
				font-weight: 700;
				margin-top: 20px;
				margin-bottom: 0;
			}

			.rechercheAlbum {
				margin-left: 20px;
				border: solid 1px #eeeeee;
				background-color: #fff;
				padding-left: 30px;
				margin-top: 18px;
				padding-top: 2px;
				font-weight: 500;
				padding-bottom: 2px;
				border-radius: 60px;
				outline: none;
				width: 150px;
				font-size: 12px;
				height: 20px;
			}
		}

		.searchIcon {
			margin-top: 21px;
			margin-left: -178px;
			height: 22px;
			object-fit: contain;
			margin-right: 120px;
		}

		.containerButtonsAlbums {
			display: flex;
			justify-content: flex-end;
			width: calc(100vw - 575px);
			min-width: 830px;
			margin-top: 20px;
			padding-right: 40px;
			gap: 10px;

			.buttonAlbums {
				display: flex;
				padding-left: 10px;
				padding-right: 10px;
				border-radius: 5px;
				height: 30px;
				align-items: center;
				gap: 3px;
				background-color: #eeeeee;
				cursor: pointer;

				.icon {
					object-fit: contain;
					margin-right: 5px;
					max-width: 13px;
				}

				.titleButton {
					display: flex;
					margin: 0;
					font-size: 12px;
					font-weight: 600;
					white-space: nowrap;
				}
			}
		}

		.horizontalBarPhotos {
			margin-bottom: 0px;
			width: calc(100vw - 265px);
			min-width: 1070px;
			height: 1px;
			background-color: #eeeeee;
		}

		.containerRecentFavoris {
			display: flex;
			margin-top: 25px;
			margin-bottom: 25px;
			gap: 25px;

			.cardLittleAlbum:hover {
				.filter {
					opacity: 0.6;
				}
			}

			.cardLittleAlbum {
				position: relative;
				width: 270px;
				height: 70px;
				border-radius: 8px;
				margin-bottom: 17.1px;
				cursor: pointer;
				transition: opacity 0.3s;
				-webkit-transition: opacity 0.3s;

				img {
					width: 270px;
					height: 70px;
					border-radius: 8px;
					object-fit: cover;
					opacity: 1;
				}

				.filter {
					position: absolute;
					background-color: rgba(0, 0, 0, 0.22);
					width: 270px;
					height: 70px;
					border-radius: 8px;
					transition: opacity 0.3s;
					-webkit-transition: opacity 0.3s;
					opacity: 1;
				}

				.notif {
					margin-top: -21.2px;
					margin-left: 280.5px;
					width: 8.5px;
					height: 8.5px;
					border-radius: 25.5px;
					background-color: #e8c0a6;
				}

				.text {
					position: absolute;
					margin-top: -60px;
					width: 259.2px;
					height: 77.3px;
					align-items: center;

					.title {
						margin-left: 20px;
						font-size: 17px;
						font-weight: 600;
						color: white;
					}

					.subTitle {
						margin-left: 13.1px;
						font-size: 11.1px;
						margin-top: -13.1;
						font-weight: 400;
						color: white;
					}
				}
			}
		}

		.noAlbumSharedContainer {
			display: flex;
			background-color: rgba(255, 216, 118, 0.21);
			border-radius: 10px;
			padding-right: 15px;
			height: 50px;
			align-items: center;
			margin-top: 10px;

			.warningIcon {
				width: 20px;
				object-fit: contain;
				margin-left: 15px;
			}

			.textNoAlbumShared {
				font-size: 13px;
				font-weight: 600;
				margin-left: 10px;
				color: #ffb100;
			}
		}

		.title {
			margin-top: 10px;
			margin-bottom: 12.7px;
			margin-left: 25.5px;
			font-size: 19.5px;
			font-weight: 600;
		}

		.combleAlbum {
			width: calc(100vw - 930px);
			min-width: 80px;
			background-color: #2e507e;
		}
	}

	.placeholderVisits {
		width: calc(100vw - 300px);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: -20px;

		.placeholderVisit1 {
			width: 200px;
			margin-right: 40px;
		}

		.textPartPlaceholderVisits {
			display: flex;
			flex-direction: column;

			.titlePlaceholderVisits {
				font-weight: 700;
				font-size: 24px;
				margin-bottom: 0px;
				margin-top: 0px;
			}

			.subtitlePlaceholderVisits {
				width: 450px;
				opacity: 0.7;
				line-height: 25px;
				font-size: 14px;
			}

			.buttonPlaceholderVisits:hover {
				cursor: pointer;
				transform: scale(1.02);
			}

			.buttonPlaceholderVisits {
				margin-top: 20px;
				color: #ffffff;
				background-color: #1c57dd;
				padding: 10px 20px;
				border-radius: 5px;
				font-size: 14px;
				transition: transform ease-in-out 0.2s;
				margin-bottom: 8px;
			}
		}
	}

	.albumList {
		.inner {
			margin-top: 10px;

			.titlePart {
				font-weight: 700;
				font-size: 19px;
				display: flex;
				align-items: center;
			}

			.countAlbums {
				color: #5066e4;
				font-size: 16px;
				margin-left: 7px;
			}

			.infosAutorisationVisit {
				font-weight: 500;
				font-size: 14px;
				margin-top: -10px;
				color: #8c8c8c;
			}

			.buttonScrollVisits:hover,
			.buttonScrollVisitsRight:hover {
				cursor: pointer;
				transform: scale(1.03);
			}

			.buttonScrollVisitsRight {
				z-index: 2;
				white-space: nowrap;
				width: 50px;
				height: 50px;
				border-radius: 99px;
				background-color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: -80px;
				margin-top: 140px;
				box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.24);
				transition: transform ease-in-out 0.2s;

				img {
					width: 10px;
				}
			}

			.buttonScrollVisits {
				z-index: 2;
				width: 50px;
				height: 50px;
				border-radius: 99px;
				background-color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: center;
				right: 0;
				margin-right: 30px;
				margin-left: -80px;
				margin-top: 140px;
				box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.24);
				transition: transform ease-in-out 0.2s;

				img {
					width: 10px;
					transform: rotate(180deg);
				}
			}

			.containerVisits {
				padding-top: 10px;
				display: flex;
				flex-direction: row;
				overflow: scroll;
				gap: 25px;
				height: 380px;
				margin-left: -30px;
				padding-left: 30px;

				.combleVisits {
					display: flex;
					width: 10px;
					height: 30px;
				}
			}

			.containerAlbums {
				margin-top: 10px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				max-height: calc(100vh - 90px);
				width: calc(100vw - 240px);
				min-width: 677px;
				gap: 25px;
			}

			.noResultAlbums {
				width: calc(100vw - 266px);
				min-width: 650px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				margin-top: 20px;

				.searchIconResearch {
					margin-top: 60px;
					width: 30px;
				}

				.textNoResult {
					font-size: 13px;
					color: #8c8c8c;
					font-weight: 500;
					letter-spacing: 0.6px;
					margin-bottom: 8px;
				}

				.secondTextNoResult {
					margin: 0;
					font-size: 12px;
					color: #8c8c8c;
					font-weight: 400;
					letter-spacing: 0.6px;
				}
			}

			.placeholderAlbums {
				width: calc(100vw - 260px);
				height: 400px;
				margin-left: 10px;
				align-items: center;
				justify-content: center;
				display: flex;
				flex-direction: column;

				img {
					margin-top: -70px;
					width: 45px;
					object-fit: contain;
					border-radius: 10px;
				}

				.titlePlaceholder {
					margin-top: 14px;
					font-size: 25px;
					font-weight: 700;
					margin-bottom: 10px;
				}

				.subtitlePlaceholder {
					margin-top: 6px;
					font-size: 14px;
					font-weight: 500;
					margin-bottom: 10px;
					text-align: center;
					opacity: 0.7;
				}
			}
		}

		.inner::-webkit-scrollbar {
			display: none;
		}
	}

	.albumOpen {
		margin-left: -17.1px;

		.bigWidgetHeaderAlbum {
			.innerBigWidgetHeaderAlbum {
				margin-top: 62.9px;
				height: 29.7px;
				margin-left: 25.5px;
				border-radius: 8.5px;
				background-color: #f8f8f8;
				width: calc(100vw - 552.5px);
				transition: height 1s;
			}
		}

		.widgetHeaderAlbum {
			.innerWidgetHeaderAlbum {
				margin-top: 62.9px;
				height: 29.7px;
				margin-left: 25.5px;
				border-radius: 8.5px;
				background-color: #f8f8f8;
				width: calc(100vw - 620.5px);
				transition: height 1s;
			}
		}

		.penButton {
			display: flex;
			padding-left: 0;
			padding-top: 0;
			margin-right: 76.5px;
			margin-top: 8.5px;
			height: 17.1px;
			border-radius: 65.11px;
			background-color: rgba(255, 255, 255, 0);
			border: 1px rgba(255, 255, 255, 0);
			cursor: pointer;
			opacity: 1;

			.penIcon {
				margin-top: -2px;
				height: 17.1px;
				border-radius: 17.1px;
				opacity: 0.7;
			}
		}

		.penButton:hover {
			opacity: 0.4;
		}

		.row {
			display: flex;
			flex-direction: row;

			.title {
				margin-left: 13.4px;
				margin-top: 0;
				font-size: 19.5px;
				font-weight: 600;
			}

			.count {
				margin-left: 13.1px;
				margin-top: 5.7px;
				font-size: 13.1px;
				font-weight: 600;
				color: #c4c4c4;
			}

			.albumTitle {
				font-size: 11.5px;
				font-weight: bold;
				margin: -1.5px 3.4px 0 13.6px;
			}
		}
	}

	.create {
		.image-upload {
			margin-top: -38.2px;
			margin-left: 42.5px;

			.importIcon {
				height: 36.5px;
				cursor: pointer;
			}
		}

		.importIcon:hover {
			opacity: 0.7;
		}

		input[type='file'] {
			display: none;
		}

		.elements {
			margin-top: -34.1px;
			margin-left: -161.5px;
			right: 0;
			margin-right: 42.5px;
			font-weight: 600;
			color: black;
			font-size: 11.1px;
		}

		.newAlbumWidget {
			margin-left: -17.1px;
			padding-left: 13.1px;
			font-weight: 400;
			padding-right: 6.5px;
			width: 269.45px;
			height: 28.1px;
			background-color: #f8f8f8;
			border-width: 0;
			border-radius: 8.5px;
			outline: none;
			font-size: 11.1px;
			display: flex;
			align-items: center;
			flex-direction: row;

			p {
				margin-top: 0;
				margin-bottom: 0;
				font-size: 11.1px;
				font-weight: 600;
				color: #4a4a4a;
			}

			.iconPlus {
				margin-left: 130.9px;
				width: 11.1px;
				height: 11.1px;
			}
		}

		.creerButton {
			margin-top: -46.7px;
			margin-left: 229.5px;
			border: solid 0;
			height: 34.1px;
			background-color: #ffffff;
			cursor: pointer;

			.creer {
				width: 68.1px;
				border: solid 0;
				background-color: #ffffff;
			}
		}

		.creerButton:hover {
			opacity: 0.7;
		}
	}
}

.cardAlbum:hover {
	.albumImage {
		transform: scale(1.04);
	}
}

.cardAlbum {
	position: relative;
	width: 270px;
	height: 310px;
	border-radius: 8px;
	cursor: pointer;

	.eye {
		width: 20px;
		height: 20px;
	}

	.filter {
		position: absolute;
		background-color: rgba(0, 0, 0, 0.22);
		width: 270px;
		height: 270px;
		border-radius: 8px;
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
		opacity: 1;
	}

	.albumImage {
		position: relative;
		transition: transform ease-in-out 0.2s;

		img {
			width: 100%;
			height: 270px;
			border-radius: 8px;
			object-fit: cover;
		}

		.containerMatterportIcon {
			display: flex;
			position: absolute;
			right: 0;
			top: 0;
			background-color: #fff8f8;
			border-radius: 5px;
			margin-right: 8px;
			margin-top: 8px;
			padding: 5px;

			.iconMatterport {
				height: 40px;
				width: 40px;
				object-fit: contain;
				box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
			}
		}
	}

	.pastilleVerte {
		display: flex;
		position: absolute;
		width: 9px;
		height: 9px;
		border-radius: 13px;
		right: 0;
		margin-top: 13px;
		margin-right: 5px;
		margin-left: 0;
	}

	.pastilleOrange {
		display: flex;
		position: absolute;
		width: 9px;
		height: 9px;
		right: 0;
		margin-top: 13px;
		margin-right: 20px;
		margin-left: 8px;
		border-radius: 13px;
	}

	.diminutifAgence {
		display: flex;
		position: absolute;
		border-radius: 13px;
		right: 0;
		background-color: #4967ed;
		color: #ffffff;
		font-size: 11px;
		font-weight: 700;
		padding: 3px 8px;
		letter-spacing: 1px;
		margin: 10px 0px 0px 8px;
	}

	.text {
		display: flex;

		.title {
			margin-left: 0px;
			margin-right: 15px;
			font-size: 14px;
			font-weight: 600;
			color: #000000;
			margin-top: -3px;
		}
	}
}

.visitCard:hover,
.firstVisitCard:hover {
	transform: scale(1.04);
}

.visitCard,
.firstVisitCard {
	display: flex;
	flex-direction: column;
	background-color: #f2f6fc;
	width: 310px;
	height: 330px;
	border-radius: 10px;
	padding: 15px;
	transition: transform ease-in-out 0.2s;
	cursor: pointer;

	.mosaiquePhotos {
		margin-top: -15px;
		margin-left: -15px;
		display: flex;
		width: 340px;
		height: 145px;
		flex-wrap: wrap;
		margin-bottom: 10px;
		border-radius: 10px 10px 0px 0px;
		overflow: hidden;

		.visitCardImage {
			width: 25%;
			height: 72.5px;
			object-fit: cover;
		}
	}

	.headerVisitCard {
		display: flex;
		align-items: center;

		.projectPicture {
			width: 65px;
			height: 55px;
			border-radius: 5px;
			object-fit: cover;
			margin-right: 15px;
		}

		.visitName {
			font-size: 16px;
			font-weight: 700;
			margin: 0 0 5px;
			padding-top: 7px;
		}

		.visitDate {
			margin: 0;
			font-size: 13px;
		}

		.chevronRightVisit {
			width: 23px;
			height: 23px;
			object-fit: contain;
			margin-left: auto;
			margin-right: 5px;
			margin-top: 2px;
		}
	}

	.commentsTitle {
		font-weight: 600;
		font-size: 14px;
		margin-bottom: 0px;
	}

	.comments {
		margin-top: 5px;
		font-size: 14px;
		line-height: 19px;
		font-weight: 400;
		color: #737373;
		height: 65px;
		overflow: hidden;
		/* Cache le texte qui dépasse la hauteur maximale */
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}

	.footerVisitCard {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 5px;

		.creatorComponent {
			display: flex;
			align-items: center;

			.innitiales {
				background-color: #85a2f5;
				width: 37px;
				height: 37px;
				border-radius: 99px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 700;
				color: #ffffff;
				letter-spacing: 1px;
				margin-right: 13px;
				font-size: 15px;
				border: 2px #ffffff solid;
			}

			.createdBy {
				margin: 0;
				margin-bottom: 0px;
				font-size: 13px;
				opacity: 0.7;
			}

			.creator {
				margin: 0;
				font-size: 14px;
				font-weight: 600;
			}
		}

		.pastilleVerte {
			display: flex;
			width: 9px;
			height: 9px;
			border-radius: 13px;
			right: 0;
		}

		.pastilleOrange {
			display: flex;
			width: 9px;
			height: 9px;
			right: 0;
			border-radius: 13px;
			margin-left: 7px;
			margin-right: 12px;
		}
	}
}

.firstVisitCard {
	background-image: linear-gradient(to bottom, #2d59ea, #629fef);
	color: #ffffff;

	.comments {
		color: #ffffff;
	}
}

.cardPhoto {
	width: 150px;
	height: 150px;
	margin-right: 2px;
	margin-bottom: 2px;
	border: solid 0;
	cursor: pointer;
	transition: transform 0.2s ease;

	&:active {
		cursor: grabbing;
	}

	&:hover {
		opacity: 0.7;
	}

	.photo {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.fermer {
	cursor: pointer;
}

// Modal Album

.modalAlbumPhoto,
.modalAlbumVisit {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 750px;
	max-width: 90vw;
	max-height: 90vh;
	border-radius: 10px;
	background-color: #ffffff;
	outline: none;
	box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);
	overflow: scroll;

	.couvImageAlbum {
		transition: all 0.3s;
		-webkit-transition: all 0.3s;
		opacity: 1;
	}

	.couvImageAlbum:hover {
		cursor: pointer;
		opacity: 0.7;
	}

	.title {
		margin-left: 17.1px;
		margin-top: 12.75px;
		margin-bottom: 8.5px;
		font-size: 18.7px;
	}

	.choiceAcces {
		margin-top: 0;
		width: 45px;
		height: 25px;
		border-radius: 100px;
		display: flex;
		align-items: center;
		transition: all 0.3s;
		-webkit-transition: all 0.3s;

		.buttonChoice:hover {
			opacity: 0.9;
			cursor: pointer;
		}

		.buttonChoice {
			width: 21px;
			height: 21px;
			border-radius: 99px;
			background-color: #ffff;
			box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);
			transition: all 0.3s;
			-webkit-transition: all 0.3s;
		}
	}

	.enregistrerButton:hover {
		opacity: 0.7;
	}

	.enregistrerButton {
		margin-top: 2px;
		margin-left: -70px;
		width: 100px;
		height: 25px;
		border-radius: 5px;
		background-color: #d2e7f8;
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
		opacity: 1;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		.enregistrerModif {
			margin: 0;
			font-size: 13px;
			font-weight: 600;
			color: #3e51a9;
		}
	}

	.enregistrer {
		margin-top: -42.5px;
		margin-left: 151.3px;
		border: 0 solid;
		background-color: #e8c0a6;
		color: #ffffff;
		font-weight: 500;
		border-radius: 5.2px;
		height: 17.1px;
		width: 85px;
		cursor: pointer;
		transition: all 0.3s;
		-webkit-transition: all 0.3s;
	}

	.enregistrer:disabled {
		background-color: #c7c7c7;
	}

	.enregistrer:hover {
		opacity: 0.7;
	}

	.enregistrer:hover:disabled {
		background-color: #c7c7c7;
	}

	.bar {
		width: 720px;
		height: 2px;
		border-radius: 17.1px;
		background-color: #f8f8f8;
		margin-left: 17.1px;
	}

	.scrollModalAlbum {
		.inner {
			overflow-y: scroll;
			overflow-x: hidden;

			input[type='file'] {
				display: none;
			}

			.subtitle {
				font-size: 14px;
				font-weight: 600;
				margin-left: 34px;
				margin-top: 12.75px;
				margin-bottom: 8.5px;
			}

			.modifier {
				margin-left: 34px;
				margin-top: 5.2px;
				background-color: #eeeeee;
				margin-bottom: 17.1px;
				border-radius: 6.8px;
				width: 76.5px;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.3s;
				-webkit-transition: all 0.3s;
			}

			.modifier:hover {
				opacity: 0.7;
			}

			.coverImage {
				margin-top: 5.9px;
				width: 50px;
				margin-left: 34px;
				border-radius: 8.5px;
				height: 50px;
				object-fit: cover;
			}

			.designant {
				margin-top: 17.1px;
				margin-left: 42.5px;
				font-size: 13px;
				font-weight: 400;
				color: #6c6c6c;
			}

			.inputNomAlbum {
				margin-left: 25px;
				border: solid 1.5px #eeeeee;
				background-color: #fff;
				padding-left: 10px;
				margin-top: 0;
				margin-bottom: 10px;
				padding-top: 5px;
				font-weight: 500;
				padding-bottom: 5px;
				border-radius: 7px;
				outline: none;
				width: 300px;
				font-size: 13px;
				height: 24px;
			}

			.listAccessibility {
				width: 100%;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				gap: 10px;
			}

			.listInformations {
				margin-left: 34px;
				justify-content: center;
				align-items: center;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				width: 459.1px;
				overflow-x: scroll;

				.creation {
					font-size: 10.2px;
					font-weight: 400;
					color: #6c6c6c;
					margin-right: 119.1px;
				}

				.elements {
					font-size: 10.2px;
					font-weight: 400;
					color: #6c6c6c;
					margin-right: 119.1px;
				}

				.consultations {
					font-size: 10.2px;
					font-weight: 400;
					color: #6c6c6c;
				}
			}

			.creer {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #4867ecff;
				margin-left: 17.1px;
				width: 476px;
				height: 42.5px;
				border-radius: 11.1px;
				cursor: pointer;
				margin-bottom: 17.1px;
				transition: all 0.3s;
				-webkit-transition: all 0.3s;
			}

			.creer:hover {
				opacity: 0.7;
			}

			.bigBar {
				margin-top: 0;
				width: 493.1px;
				height: 3px;
				border-radius: 17.1px;
				background-color: #e3e3e3;
				margin-left: 25.5px;
			}

			.statut {
				margin-left: -55.2px;
				margin-top: -25.5px;
				height: 21.2px;
				width: 76.5px;
				font-size: 11.9px;
				font-weight: 600;
				color: #000000;
			}

			.tri {
				display: flex;
				flex-direction: row;
				margin-top: 25.5px;

				.nameTitle {
					margin-left: 76.5px;
					font-size: 11.9px;
					font-weight: 600;
					color: #000000;
				}

				.typeTitle {
					margin-left: 161.5px;
					font-size: 11.9px;
					font-weight: 600;
					color: #000000;
				}
			}
		}

		.inner::-webkit-scrollbar {
			display: none;
		}
	}

	.containerButtonsModalModifAlbum {
		display: flex;
		flex-direction: row;
		background-color: #ffffff;
		width: 750px;

		.supprimer {
			display: flex;
			margin-left: 25px;
			width: 315px;
			align-items: center;
			justify-content: center;
			margin-bottom: 23px;
			margin-top: 10px;
			border: solid 0;
			background-color: rgba(248, 210, 210, 0.51);
			color: #a93e3e;
			font-weight: 600;
			border-radius: 6px;
			padding: 8px 12px;
			font-size: 13px;
			height: 25px;
			cursor: pointer;
			transition: opacity 0.3s;
			-webkit-transition: opacity 0.3s;
			opacity: 1;
		}

		.supprimer:hover {
			opacity: 0.7;
		}
	}

	.buttonAlbumToVisit:hover {
		cursor: pointer;
		opacity: 0.7;
	}

	.buttonAlbumToVisit {
		background-color: #f8f8f8;
		margin-left: 28px;
		margin-right: 28px;
		height: 40px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 25px;
		font-size: 13px;
		font-weight: 700;
		transition: opacity ease-in-out 0.2s;
	}
}

.modalAlbumVisit {
	margin-top: 300px;
	width: 501px;
	margin-left: calc((100vw - 501px) / 2);

	.bar {
		width: 470px;
	}

	.scrollModalAlbum {
		.inner {
			.inputNomAlbum {
				width: 440px;
			}
		}
	}

	.containerButtonsModalModifAlbum {
		.supprimer {
			width: 190px;
		}
	}
}

.overlayModalAlbumPhoto {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(217, 217, 217, 0.7);

	&.darkerOverlay {
		background-color: rgba(0, 0, 0, 0.3);
	}
}

// Modal Photo (Aperçu)

.overlayModalPhoto {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgb(0, 0, 0);
}

.modalPhoto {
	background-color: rgba(255, 255, 255, 0);
	position: absolute;
	min-height: 467.5px;
	border-radius: 8.5px;
	box-shadow: 0 0 25.5px 5.2px rgba(0, 0, 0, 0);
	outline: none;

	.pdfViewer {
		width: 100vw;
		height: calc(100vh - 60px);
		margin-top: 60px;
	}

	.containerAnnotationIcon {
		background-color: #ffffff;
		padding: 0px 8px;
		padding-bottom: 16px;
		margin-top: 15px;
		height: 35px;
		width: 400px;
		position: absolute;
		align-self: center;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		color: #1c57dd;
		font-weight: 600;
		font-size: 16px;
		border-bottom: 1px #e3e3e3 solid;

		.annotationIcon {
			width: 18px;
			object-fit: contain;
		}

		.numberAnnotations {
			background-color: #1c57dd;
			color: #ffffff;
			border-radius: 99px;
			font-size: 13px;
			font-weight: 600;
			width: 22px;
			height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.containerBackMenu {
		display: flex;
		position: absolute;
		top: 0;
		justify-content: space-between;
		width: 100vw;
		background-color: #292929;

		.goBackButton:hover {
			cursor: pointer;
			opacity: 0.7;
		}

		.goBackButton {
			padding: 20px;
			transition: opacity ease-in-out 0.2s;

			.icon {
				width: 20px;
			}
		}

		.threeDots {
			display: flex;
			flex-direction: column;
			margin-right: 20px;
			width: 20px;
			justify-content: center;
			align-items: center;
			gap: 3px;

			.dot {
				width: 5px;
				height: 5px;
				background-color: #000;
				border-radius: 10px;
			}
		}

		.previousNext:hover {
			opacity: 0.7;
		}

		.previousNext {
			width: 10px;
			padding: 10px;
			cursor: pointer;
			transition: opacity ease-in-out 0.2s;
			margin-top: 4px;

			img {
				width: 10px;
			}

			.rightIcon {
				transform: rotate(180deg);
			}
		}
	}

	.photoOuvert {
		max-width: calc(100vw - 500px);
		height: calc(100vh - 170px);
		object-fit: contain;
	}

	.containerZoomNav {
		width: calc(100vw - 400px);
		display: flex;
		position: absolute;
		bottom: 0;
		align-items: center;
		justify-content: center;
		height: 90px;
		gap: 100px;

		.previousNext:hover {
			background-color: #232323;
			cursor: pointer;
		}

		.previousNext {
			background-color: #0c0c0c;
			width: 40px;
			height: 40px;
			border-radius: 99px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: background-color ease-in-out 0.1s;

			.leftIcon,
			.rightIcon {
				width: 8px;
			}

			.rightIcon {
				transform: rotate(180deg);
			}
		}

		.zoomInOut {
			background-color: #0c0c0c;
			border-radius: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
			padding: 2px;

			.buttonZoom:hover {
				opacity: 1;
				background-color: #232323;
				cursor: pointer;
			}

			.buttonZoom,
			.buttonZoomDisable {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				border-radius: 99px;
				border: solid 0px;
				background-color: rgba(0, 0, 0, 0);
				color: #ffffff;
				font-size: 25px;
				transition: background-color ease-in-out 0.1s;

				.zoomOutIcon {
					width: 17px;
				}
			}

			.buttonZoomDisable {
				opacity: 0.5;
			}
		}
	}
}

.fermer {
	top: 0;
	left: 0;
	margin-top: calc((50vh - 3vh) * (-1));
	margin-left: -68.1px;
	position: absolute;
	border: 0 solid;
	background-color: rgba(255, 255, 255, 0);
	cursor: pointer;

	img {
		width: 17.1px;
	}
}

.deletePicture {
	top: 0;
	right: 0;
	margin-top: calc((50vh - 3vh) * (-1));
	margin-right: 0;
	position: absolute;
	border: 0 solid;
	background-color: rgba(255, 255, 255, 0);
	cursor: pointer;

	img {
		width: 17.1px;
	}
}

.download {
	top: 0;
	right: 0;
	margin-top: calc((50vh - 3vh) * (-1));
	margin-right: 34px;
	position: absolute;
	border: 0 solid;
	background-color: rgba(255, 255, 255, 0);
	cursor: pointer;

	img {
		width: 17.1px;
	}
}

.share {
	top: 0;
	right: 0;
	margin-top: calc((50vh - 3vh) * (-1));
	margin-right: 68.1px;
	position: absolute;
	border: 0 solid;
	background-color: rgba(255, 255, 255, 0);
	cursor: pointer;

	img {
		width: 17.1px;
	}
}

.star {
	top: 0;
	right: 0;
	margin-top: calc((50vh - 3vh) * (-1));
	margin-right: 68.1px;
	position: absolute;
	border: 0 solid;
	background-color: rgba(255, 255, 255, 0);
	cursor: pointer;

	img {
		width: 17.1px;
	}
}

.fermer:hover {
	opacity: 0.7;
}

.transformRotate {
	transform: rotate(180deg);
}

.player-wrapper {
	position: relative;
	padding-top: 56.25%;
	/* Player ratio: 100 / (1280 / 720) */
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

.photosAlbum {
	outline: none;

	.goBackButton {
		display: flex;
		margin-top: 8px;
		background-color: #eeeeee;
		padding-left: 10px;
		padding-right: 10px;
		border-radius: 5px;
		height: 30px;

		.iconGoBack {
			margin-top: 9px;
			height: 11px;
			margin-right: 5px;
			object-fit: contain;
		}

		.textGoBack {
			margin-left: 3px;
			font-size: 12px;
			margin-top: 7px;
			font-weight: 600;
			color: #000000;
		}
	}

	.title {
		margin-top: 10px;
		margin-bottom: 12.7px;
		margin-left: 15px;
		font-size: 19.5px;
		font-weight: 600;
	}

	.parametresAlbum {
		margin-left: 5px;
		margin-top: 0;
		width: 23px;
		object-fit: contain;
		opacity: 0.8;
		margin-right: 5px;
	}

	.diminutifAgence {
		border-radius: 13px;
		margin-top: 10px;
		height: 18px;
		margin-right: 10px;
		margin-left: 8px;
		color: #ffffff;
		font-size: 14px;
		padding: 3px 8px;
		letter-spacing: 1px;
	}

	.longBarAlbum {
		margin-top: 0;
		width: calc(100vw - 255px);
		height: 1px;
		background-color: #eeeeee;
		border-radius: 20px;
		margin-left: 30px;
		min-width: 600px;
	}

	.containerButtonPhotos {
		width: 600px;
		margin-left: -600px;
		display: flex;
		justify-content: flex-end;
		gap: 10px;

		.buttonPhoto {
			display: flex;
			margin-top: -40px;
			padding-left: 10px;
			padding-right: 10px;
			border-radius: 5px;
			height: 30px;
			gap: 3px;
			background-color: #eeeeee;

			.iconButtonPhoto {
				margin-top: 8px;
				height: 14px;
				margin-right: 5px;
				object-fit: contain;
			}

			.textButtonPhoto {
				font-size: 12px;
				margin-top: 7px;
				font-weight: 600;
			}
		}
	}

	.photoList {
		input[type='file'] {
			display: none;
		}

		.inner {
			margin-left: 34px;
			padding-top: 19.5px;
			padding-bottom: 21.2px;
			overflow-x: scroll;
			display: flex;
			justify-content: flex-start;
			flex-direction: row;
			width: calc(100vw - 255px);
			flex-wrap: wrap;
			max-height: calc(100vh - 157px);
			min-width: 600px;
			gap: 20px;

			.buttonPlus {
				display: flex;
				align-items: center;
				height: 30px;
				cursor: pointer;
				margin-left: 10px;

				.bluePlus {
					margin-right: 8px;
					width: 20px;
					object-fit: contain;
				}

				.textButton {
					margin: 0;
					font-size: 14px;
					font-weight: 600;
					color: #5066e4;
				}
			}

			.littleTitle {
				font-weight: 700;
				margin: 0;
			}

			.bar {
				background-color: #eeeeee;
				height: 1px;
				margin-top: 5px;
			}

			.threeSixtyPart,
			.matterportPart {
				min-height: 90px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 20px;
				margin-top: 0px;
				width: calc(100vw - 255px);
			}

			position: absolute;

			.placeholderAlbums {
				width: calc(100vw - 255px);
				height: 400px;
				min-height: 500px;
				min-width: 850px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				transition: all 0.3s;
				-webkit-transition: all 0.3s;

				.placeholderPhotoAlbums {
					transition: all 0.5s;
					-webkit-transition: all 0.5s;
					width: 850px;
				}

				.titlePlaceHolder {
					font-weight: 600;
					font-size: 25px;
					margin-bottom: 10px;
					transition: all 0.7s;
					-webkit-transition: all 0.7s;
				}

				.subtitlePlaceHolder {
					font-weight: 400;
					color: #7a7a7a;
					margin: 0;
					transition: all 0.5s;
					-webkit-transition: all 0.5s;
				}

				.button:hover {
					cursor: pointer;
					opacity: 0.8;
				}

				.button {
					padding-top: -1px;
					margin-top: 20px;
					width: 160px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 7px;
					background-color: #d2e7f8;
					transition: all 0.3s;
					-webkit-transition: all 0.3s;

					.textButton {
						color: #4867ecff;
						margin: 0;
						font-size: 13px;
						font-weight: 600;
					}
				}
			}
		}

		.inner::-webkit-scrollbar {
			display: none;
		}
	}

	.importPhotoWidgetContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 0;
		right: 0;
		margin-right: 0;
		height: 135px;
		overflow: hidden;
		transition: width 0.3s ease-in-out;

		.importPhotoWidget {
			width: 300px;
			height: 75px;
			box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.1);
			border-radius: 10px;
			background-color: #ffffff;

			.title {
				margin-top: 12px;
				font-size: 15px;
				font-weight: 600;
				margin-bottom: 17px;
			}

			.backBar {
				width: 270px;
				margin-left: 15px;
				background-color: #f8f8f8;
				height: 10px;
				border-radius: 20px;

				.frontBar {
					height: 7px;
					background-color: #4f66e3;
					border-radius: 20px;
				}
			}
		}
	}

	.visitInfos {
		width: 400px;
		margin-left: 25px;
		height: calc(100vh - 55px);
		background-color: #2d59ea;
		background: linear-gradient(180deg, #2d59ea, #629fef);

		.projectImage {
			width: 100%;
			height: 300px;
			object-fit: cover;
		}

		.visitNumber {
			margin-top: 20px;
			margin-left: 25px;
			font-size: 17px;
			font-weight: 600;
			color: #ffffff;
			margin-right: 10px;
			margin-bottom: 15px;
		}

		.pastille {
			margin-top: 5px;
			width: 8px;
			height: 8px;
			border-radius: 13px;
			margin-right: 5px;
		}

		.visitDate {
			margin-top: -10px;
			margin-left: 25px;
			font-size: 13px;
			font-weight: 600;
			color: #ffffff;
		}

		.whiteBar {
			width: calc(100% - 50px);
			height: 1px;
			background-color: #ffffff;
			opacity: 0.5;
			margin: 5px 25px 15px;
		}

		.createdBy {
			font-weight: 600;
			font-size: 14px;
			color: #ffffff;
		}

		.profilePicture {
			width: 35px;
			height: 35px;
			background-color: #ffffff;
			border-radius: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 600;
			color: #2d59ea;
			font-size: 14px;
			border: solid 2px #629fef;
		}

		.containerRemarques {
			background-color: #6d9ef0;
			width: calc(100% - 80px);
			margin-left: 25px;
			margin-top: 20px;
			border-radius: 10px;
			padding: 1px 15px;
			margin-bottom: 20px;

			.titleRemarques {
				margin-top: 10px;
				font-weight: 600;
				color: #ffffff;
				font-size: 15px;
				margin-bottom: 0px;
			}

			.editRemarque {
				margin-top: 9px;
				color: #ffffff;
				font-size: 13px;
				line-height: 18px;
				border: solid 2px #52e728;
				background-color: rgba(255, 255, 255, 0);
				padding: 10px;
				border-radius: 5px;
				width: 295px;
				margin-bottom: 10px;
				outline: none;
				font-weight: 500;
			}

			.remarque {
				margin-top: 9px;
				color: #ffffff;
				font-size: 13px;
				line-height: 18px;
				max-height: calc(100vh - 640px);
				overflow: scroll;
			}

			.buttonEdit:hover {
				cursor: pointer;
			}

			.buttonEdit {
				padding: 10px;
				background-color: #1c57dd;
				color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				border-radius: 5px;
				margin-bottom: 14px;
				font-weight: 600;
			}
		}

		.titleConfirmConsult {
			margin-top: 20px;
			font-weight: 600;
			color: #ffffff;
			font-size: 14px;
			margin-bottom: 0px;
			margin-left: 25px;
		}
	}
}

.menuBigPhoto {
	position: absolute;
	margin-right: 50px;
	margin-top: 18px;
	top: 0;
	right: 0;
	z-index: 10;
	transition: opacity 0.3s ease-in-out;
	opacity: 0;
	background-color: #fff;
	border-radius: 7px;
	overflow: hidden;
	box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);
	height: fit-content;
	width: fit-content;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	pointer-events: none;

	&.show {
		opacity: 1;
		pointer-events: all;
	}

	.crossIconMenu {
		width: 20px;
		object-fit: contain;
		display: flex;
		margin-top: -20px;
	}

	.buttonMenuBigPhoto:hover {
		background-color: #f3f3f3;
	}

	.buttonMenuBigPhoto {
		height: 40px;
		display: flex;
		align-items: center;
		padding-left: 10px;
		padding-right: 10px;
		background-color: #ffffff;
		font-size: 15px;
		font-weight: 500;
		border-radius: 7px;
		cursor: pointer;
	}
}

.overlayBackgroundBigPhoto {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(234, 192, 192, 0.87);
}

.modalAddPictureToAlbum {
	position: absolute;
	top: 0;
	margin-top: calc((100vh - 750px) / 2);
	margin-left: calc((100vw - 1300px) / 2);
	width: 1300px;
	height: 750px;
	background-color: #ffffff;
	border-radius: 10px;
	outline: none;

	.crossIcon:hover {
		opacity: 0.8;
	}

	.crossIcon {
		width: 23px;
		object-fit: contain;
		position: absolute;
		right: 0;
		margin-top: 10px;
		margin-right: 10px;
		cursor: pointer;
		transition: opacity 0.3s;
		-webkit-transition: opacity 0.3s;
		opacity: 1;
	}
}

.containerMatterportList {
	width: calc(100vw - 230px);
	margin-left: 35px;
	margin-top: 0;
	max-height: calc(100vh - 165px);
	overflow: scroll;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 30px;
	padding-top: 30px;

	.itemVisit {
		min-width: 400px;
		height: 380px;
		display: flex;
		align-items: center;
		border-radius: 20px;
		overflow: hidden;
		flex-direction: column;
		cursor: pointer;
	}

	.title {
		font-weight: 700;
		font-size: 18px;
		margin-top: 15px;
		margin-bottom: 0;
	}

	.date {
		font-weight: 500;
		font-size: 13px;
		margin-top: 5px;
		margin-left: 15px;
		opacity: 0.7;
	}

	.imgVisit {
		width: 93%;
		object-fit: cover;
		height: 300px;
		border-radius: 15px;
	}
}

.modalViewMatterport {
	position: absolute;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: #ffffff;
	outline: none;
	box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);
	display: flex;

	.leftPart {
		width: calc(100vw - 350px);
		transition: width ease-in-out 0.2s;
		height: 100vh;
	}

	.rightPart {
		width: 350px;
		position: absolute;
		right: 0;

		.matterportImg {
			margin-top: 80px;
			width: 280px;
			margin-left: 35px;
			border-radius: 20px;
			cursor: pointer;
			object-fit: cover;
		}

		.password {
			margin-top: 20px;
			width: 270px;
			background-color: #eeeeee;
			padding-left: 10px;
			font-weight: 600;
			font-size: 13px;
			padding-top: 10px;
			padding-bottom: 10px;
			border-radius: 10px;
		}
	}
}

.modalNewView {
	position: absolute;
	top: 0;
	margin-top: calc((100vh - 640px) / 2);
	margin-left: calc((100vw - 700px) / 2);
	width: 700px;
	height: 600px;
	border-radius: 10px;
	background-color: #ffffff;
	outline: none;
	overflow: scroll;
	box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);
	display: flex;
	align-items: center;
	flex-direction: column;

	.crossIcon:hover {
		opacity: 0.8;
	}

	.crossIcon {
		width: 23px;
		object-fit: contain;
		position: absolute;
		right: 0;
		margin-top: 5px;
		margin-right: 5px;
		cursor: pointer;
		transition: all 0.3s;
		-webkit-transition: all 0.3s;
	}

	.title {
		font-weight: 700;
		font-size: 23px;
	}

	.nameInput {
		display: flex;
		font-size: 11px;
		background-color: #ffffff;
		padding-left: 5px;
		padding-right: 5px;
		margin-left: 30px;
		margin-bottom: -7px;
		z-index: 2;
		width: fit-content;
	}

	.input {
		margin-left: 0;
		padding-left: 12px;
		font-size: 13px;
		font-weight: 500;
		width: 584px;
		height: 40px;
		border: solid 1px #b7b7b7;
		border-radius: 10px;
		outline: none;
	}

	.littleInput {
		z-index: 1;
		margin-right: 20px;
		padding-left: 12px;
		font-size: 13px;
		font-weight: 500;
		width: 274px;
		height: 40px;
		border: solid 1px #b7b7b7;
		border-radius: 10px;
		outline: none;
		background-color: #ffffff;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	.fleche {
		margin-left: -40px;
		transform: rotate(90deg);
		margin-right: 34px;
	}

	.buttonPublier {
		padding: 13px 30px;
		background-color: #1c57dd;
		color: #fff;
		font-weight: 600;
		font-size: 15px;
		border: solid 1px;
		margin-bottom: 50px;
		margin-top: 50px;
		margin-right: 20px;
		border-radius: 10px;
		cursor: pointer;
		transition: opacity ease-in-out 0.2s;
		align-items: center;
		justify-content: center;
		display: flex;
	}

	.buttonPublier:hover {
		opacity: 0.7;
	}

	.buttonSupprimer {
		padding: 13px 30px;
		background-color: #d73232;
		color: #fff;
		font-weight: 600;
		font-size: 15px;
		border: solid 1px;
		margin-bottom: 50px;
		margin-top: 50px;
		margin-right: 20px;
		border-radius: 10px;
		cursor: pointer;
		transition: opacity ease-in-out 0.2s;
		align-items: center;
		justify-content: center;
		display: flex;
	}

	.buttonSupprimer:hover {
		opacity: 0.7;
	}

	.inputCoverPicture {
		font-size: 12px;
		width: 600px;
		height: calc(600px * 0.565);
		border-radius: 10px;
		outline: none;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		color: #8d8d8d;
		cursor: pointer;

		#inputCoverPicture[type='file'] {
			display: none;
		}

		.photos:hover {
			opacity: 0.8;
		}

		.photos {
			background-color: #eeeeee;
			width: 600px;
			height: calc(600px * 0.565);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 20px;
			margin-top: 10px;

			.hidden {
				display: flex;
				position: absolute;
				margin-top: 17px;
			}

			.label {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 284px;
				height: 160px;
				cursor: pointer;
			}

			.image-upload {
				background-color: #eeeeee;
				width: 600px;
				height: calc(600px * 0.565);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 20px;
				margin-top: 10px;
			}

			.image-upload:hover {
				opacity: 0.7;
			}
		}
	}
}

.dropZoneAlbum {
	position: absolute;
	margin-left: 17px;
	width: calc(100vw - 235px);
	height: calc(100vh - 130px);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 2px #eeeeee;

	.textDropZoneAlbum {
		color: #8491e0;
		font-size: 28px;
		text-align: center;
		font-weight: 500;
	}
}

.matterportVisitItem {
	background-color: #f2f6fc;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	width: 350px;
	cursor: pointer;

	.leftPart {
		display: flex;
		align-items: center;
	}

	.imgMatterport {
		width: 65px;
		height: 50px;
		object-fit: cover;
		border-radius: 5px;
		margin-left: 10px;
		margin-right: 15px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.visitName {
		font-weight: 700;
		margin: 0;
		font-size: 13px;
	}

	.visitDate {
		margin: 0;
		margin-top: 3px;
		font-size: 12px;
	}

	.matterportIcon {
		width: 40px;
		object-fit: contain;
		margin-right: 20px;
	}
}

.modalAnnotations {
	top: 0;
	width: 400px;
	height: calc(100vh - 23px);
	border-radius: 10px;
	margin-top: 0px;
	background-color: #ffffff;
	outline: none;
	display: flex;
	flex-direction: column;
	align-items: center;

	.littleTitle {
		font-weight: 600;
		font-size: 16px;
	}

	.textarea {
		width: 350px;
		height: 404px;
		border-radius: 5px;
		background-color: #f1f1f1;
		border: solid 0px;
		margin-bottom: 15px;
		outline: none;
		padding: 10px;
		font-size: 14px;
		font-weight: 500;
		margin-top: 30px;
	}

	.listAnnotations {
		padding-top: 25px;
		margin-bottom: 20px;
		height: calc(100vh - 27px);
		overflow: scroll;
		border-bottom: solid 1px #e3e3e3;
		width: 100%;

		.placeholderAnnotations {
			text-align: center;
			margin-top: 20px;
			line-height: 25px;
			font-size: 14px;
			opacity: 0.7;
		}
	}

	.annotationItem {
		width: 340px;
		margin-left: 30px;

		.name {
			font-weight: 700;
			color: #1c57dd;
			font-size: 15px;
			margin-bottom: 0px;
		}

		.annotationText {
			margin-top: 5px;
			font-size: 14px;
		}
	}

	.publishAnnotation {
		background-color: #1c57dd;
		color: #ffffff;
		width: 370px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 45px;
		border-radius: 5px;
		font-size: 14px;
		font-weight: 600;
	}

	.editAnnotation {
		background-color: #f1f1f1;
		color: #000;
		width: 305px;
		margin-right: 65px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 45px;
		border-radius: 5px;
		font-size: 14px;
		font-weight: 600;

		.bigEditIcon {
			margin-left: 10px;
			width: 16px;
			object-fit: contain;
		}
	}
}

.overlayModalAnnotations {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(217, 217, 217, 0);
}

.photos-grid {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	padding: 16px;
}

.photos-grid__drop-indicator {
	transition: all 0.2s ease;
	width: 146px;
	height: 146px;
	margin-right: 2px;
	margin-bottom: 2px;
	background-color: rgba(63, 92, 246, 0.2);
	border: 2px dashed #3f5cf6;
	border-radius: 4px;
}
