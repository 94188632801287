.containerInputModalTicket {
	position: relative;
	display: flex;
	height: 35px;
	margin-top: -37px;
	width: 317px;
	margin-left: 248px;
	margin-bottom: 2px;
}

.inputModalTicket {
	z-index: 1000;
	display: flex;
	flex-direction: column;
	position: absolute;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.19);
	white-space: nowrap;
	border-radius: 7px;
	width: 317px;
	background-color: #ffffff;
	min-height: 32px;
	border: solid 1px #e0e0e0;
	margin-top: 38px;

	.inputSimple {
		outline: none;
		border: 0;
		margin-left: 5px;
		font-size: 14px;
		width: 100%;
		border-radius: 7px;
	}

	.containerInputDropDown {
		display: flex;
		justify-content: center;
		background-color: #f6f6f6;
		border-radius: 7px 7px 0px 0px;
		height: 30px;
		border-bottom: 1px solid #e0e0e0;

		.inputDropDown {
			border-radius: 7px 7px 0px 0px;
			outline: none;
			border: 0;
			margin-left: 5px;
			padding-left: 5px;
			font-size: 14px;
			background-color: #f6f6f6;
			width: 306px;
		}
	}

	.list {
		display: flex;
		flex-direction: column;
		padding-top: 10px;
		padding-left: 10px;
		padding-bottom: 5px;
		max-height: 315px;
		overflow: scroll;

		.subtitleDropDown {
			font-size: 13px;
			font-weight: 600;
			color: #727272;
			margin: 0;
			margin-bottom: 5px;
		}

		.editButton {
			font-size: 13px;
			font-weight: 600;
			margin: 0;
			margin-bottom: 5px;
			margin-right: 10px;
			color: #1c57dd;
		}

		.buttonAddLot {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f8f8f8;
			border: dashed 2px #eeeeee;
			border-radius: 10px;
			margin-right: 10px;
			margin-bottom: 10px;
			margin-top: 10px;
			font-weight: 600;
			font-size: 13px;
			height: 35px;
		}

		.containerType {
			padding: 4px 5px;
			border-radius: 5px;
			margin: 2px 5px 1px -5px;
			cursor: pointer;
			width: 297px;

			.type {
				border-radius: 5px;
				padding: 4px 7px;
				font-size: 13px;
				font-weight: 600;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}

		.containerType:hover {
			background-color: #f3f3f3;
		}

		.containerItemDropDown,
		.containerItemDropDownRename {
			padding: 4px 0px;
			border-radius: 5px;
			margin: 2px 5px 1px -5px;
			width: 307px;
			justify-content: space-between;

			&.selected {
				color: #3ca504;
			}

			.checkVert {
				width: 14px;
				margin-right: 10px;
			}

			.plusCircle {
				width: 14px;
				margin-right: 10px;
				opacity: 0.4;
				transition: opacity 0.3s ease;

				&:hover {
					cursor: pointer;
				}
			}

			.type {
				padding: 4px 7px;
				font-size: 13px;
				font-weight: 600;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}

			.formRenameOption {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e0e0e0;
				margin-left: 7px;
				padding-bottom: 5px;

				.inputRenameOption {
					outline: none;
					font-size: 13px;
					font-weight: 500;
					border: 0;
					width: 245px;
				}

				.containerEnterIcon {
					border: 0;
					background-color: #ffffff;
					padding: 0;
					.enterIcon {
						opacity: 0.4;
						width: 15px;
					}

					.enterIcon:hover {
						opacity: 1;
						cursor: pointer;
					}
				}
			}
		}

		.containerItemDropDown:hover {
			background-color: #f3f3f3;
			cursor: pointer;

			.plusCircle {
				opacity: 1;
			}
		}

		.containerAllLevel {
			.itemLevel {
				display: flex;
				align-items: center;
				position: relative;
				padding: 6px 0px;
				border-radius: 5px;
				margin: 2px 5px 1px -5px;
				width: 305px;

				.triangle {
					margin-left: 6px;
					width: 8px;
					transition: transform ease-in-out 0.2s;
				}

				.nameLevel {
					font-size: 14px;
					font-weight: 600;
					margin: 0;
					margin-left: 5px;
				}

				&:hover {
					background-color: #f3f3f3;
					cursor: pointer;
				}

				.checkVert {
					display: flex;
					position: absolute;
					right: 0;
					margin-right: 12px;
					width: 14px;
				}
			}

			.locationsList {
				.itemLocation {
					display: flex;
					align-items: center;
					position: relative;
					margin-left: 10px;
					padding: 6px 5px;
					margin-right: 8px;
					font-size: 14px;
					border-radius: 5px;
					font-weight: 600;

					.checkVert {
						display: flex;
						position: absolute;
						right: 0;
						margin-right: 12px;
						width: 14px;
					}

					&:hover {
						background-color: #f3f3f3;
						cursor: pointer;
					}
				}
			}
		}
	}
}
