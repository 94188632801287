// NEW APP

.input-hidden {
  display: none;
}

// OLD APP

$font-1: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;

$color-1: #4867EC;
$color-2: #61dafb;
$color-2: #FFFFFF;
$color-3: #282c34;
$color-4: rgb(32, 35, 42);
$color-5: #000000;

.color1 {
  color: $color-1;
}

.backgroundColor1 {
  color: $color-1;
}

input {
  font-family: $font-1 ;
}

body {
  margin: 0rem;
  font-family: $font-1;
  color: $color-3;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;

  .rowComponent {
    display: flex;
    flex-direction: row;
  }
}

.enableSelection {
  user-select: text; /* supported by Chrome and Opera */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
}

body::-webkit-scrollbar {
  display: none;
}

div::-webkit-scrollbar {
  display: none;
}

.ombre {
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  transition: all 0.3s;
}

.transitionWidthMarginLeft {
  transition: all 0.3s;
}

.transitionOpacity {
  transition: opacity 0.3s ease-in-out;

}

.transitionMenuProjet{
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

a {
  text-decoration: none;
  color: $color-3;
  cursor: pointer;
  font-family: $font-1;
}

a:visited {
  color: $color-3;
}

.overlayTransparent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0);
}

.fondChargement {
  position: absolute;
  margin-top: 100px;
  margin-left: 270px;
  overflow-x: scroll;
  width: calc( 100vw - 310px);
  height: calc( 100vh - 130.7px);
  min-height: 50px;
  max-height: 150px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;


  .grosChargement {

    object-fit: contain;
    width: 30px;
    margin-left: 0px;
    margin-top: 0px;
    transition-delay: 5s;

  }

  @keyframes rotate360 {
    from {
      width: 0px;
    }
    to { transform: rotate(360deg); }
  }
  .grosChargement { animation: 1s rotate360 infinite linear; }
}

.letterSpacing {
  letter-spacing: 0.6px;
}

.noOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.hover:hover {
  opacity: 0.7;
  cursor: pointer;
}

.hover {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}

.hoverTransition:hover {
  opacity: 0.7;
  cursor: pointer;
}

.hoverTransition {
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.hoverNoOpacity:hover {
  cursor: pointer;
}

textarea {
  resize: none;
}


.chargementContainer {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .chargement {
    opacity: 0.4;
    object-fit: contain;
    width: 20px;
  }
}

.bigChargementContainer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .chargement {
    opacity: 0.4;
    margin-top: 180px;
    object-fit: contain;
    width: 20px;
  }
}


.chargementContainerDashboard {
  margin-top: 100px;
  width: calc(100vw - 730px);
  display: flex;
  align-items: center;
  justify-content: center;

  .chargement {
    opacity: 0.4;
    margin-top: 220px;
    object-fit: contain;
    width: 20px;
  }
}

.transition {
  transition: all 0.5s ease-in-out;
}

.transition2 {
  transition: all 0.2s ease-in-out;
}


.chargementContainerFinanceProgression {
  margin-top: 100px;
  width: calc(100vw - 230px);
  display: flex;
  align-items: center;
  justify-content: center;

  .chargement {
    opacity: 0.4;
    margin-top: 220px;
    object-fit: contain;
    width: 20px;
  }
}

.rechercheParticipants {
  margin-left: 20px;
  border: solid 0px #eeeeee;
  background-color: #F8F8F8;
  padding-left: 30px;
  margin-top: 0px;
  margin-bottom: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 8px;
  outline: none;
  width: 438px;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
}

.searchIcon {
  margin-top: 6px;
  margin-left: -464px;
  height: 22px;
  object-fit: contain;
  margin-right: 230px;
  opacity: 0.5;
}

textarea {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

input {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.shadow {
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
}


@keyframes rotate360 {
  to { transform: rotate(360deg); }
}
.chargement {
  animation: 0.5s rotate360 infinite linear;
  width: 20px;
}

.isSelected {
  box-shadow: 0px 0px 20px 0px rgba(51, 51, 51, 0.1);
  padding-left: 5px;
  padding-right: 20px;
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
  margin-bottom: 22px;
  padding-bottom: 10px;
}

.selectLanguage:hover {
  background-color: #eeeeee;
}

.selectLanguage {
  background-color: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.blurred-div {
  background-color: #ffffff;
  filter: blur(5px);
}

.row {
    display: flex;
    flex-direction: row;
}

.deletion-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  border-radius: 8px;

  .deletion-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #1c57dd;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    animation: spin 1s linear infinite;
  }

  p {
    margin-top: 20px;
    font-weight: 600;
    font-size: 16px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
